jQuery(document).ready(function($) {

    var languageSwitchToggle = $('.js-language-switch-toggle');
    var languageSwitchList = $('.language-switch__list');

    languageSwitchToggle.on('click', function(event) {
        event.stopPropagation();
        languageSwitchList.toggleClass('is-active');
    });

    // languageSwitchList.on('click', function(event) {
    //     event.stopPropagation();
    // });

    $(document).click(function() {
        if (languageSwitchList.hasClass('is-active')) {
            languageSwitchList.toggleClass('is-active');
        }
    });

    $(document).on('keydown',function(event) {
        if (event.keyCode == 27) {
            if (languageSwitchList.hasClass('is-active')) {
                languageSwitchList.toggleClass('is-active');
            }
        }
    });

});