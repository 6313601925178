jQuery(document).ready(function($) {

    var searchOpen = $('.js-search-open');
    var searchInput = $('.js-search-input');
    var searchOverlay = $('.js-site-search');
    var searchClose = $('.js-search-close');

    searchOpen.on('click', function(event) {
        searchOverlay.toggleClass('is-active');
        // searchInput.get(0).focus();
        $('#s').get(0).focus();
        // searchInput.filter(':visible').focus();
        // $('#s').searchInput.filter(':visible').focus();
        setTimeout(function() {
            searchInput.focus();
        }, 500);
        event.stopPropagation();
    });

    searchOverlay.load(function() {
        searchInput.focus();
    });

    searchClose.on('click', function(event) {
        event.stopPropagation();
        searchOverlay.toggleClass('is-active');
    });

    searchOpen.on('keyup',function(event) {
        if (event.keyCode == 13) {
            $(this).click();
        }
    });

    searchInput.on('click', function(event) {
        event.stopPropagation();
    });

    $(document).on('keydown',function(event) {
        if (event.keyCode == 27) {
            if (searchOverlay.hasClass('is-active')) {
                searchOverlay.toggleClass('is-active');
            }
        }
    });

});