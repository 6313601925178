jQuery(document).ready(function($) {

    if( typeof prices_calculator !== 'undefined' &&
        prices_calculator['platina'] !== null && prices_calculator['gold'] !== null && prices_calculator['silver'] !== null && prices_calculator['gold_silver'] !== null &&
        prices_calculator['combo_platina'] !== null && prices_calculator['used'] !== null && prices_calculator['used_combo'] !== null && prices_calculator['laser'] !== null ) {

        calculator_form_id = 3;
        laser_or_stempel = $("#input_3_1");
        used_or_new = $("#input_3_2");
        metal_new = $("#input_3_3");
        metal_used = $("#input_3_22");
        qty = $("#input_3_35");

        all_elements = laser_or_stempel['selector'] + ', ' + used_or_new['selector'] + ', ' + metal_new['selector'] + ', ' + metal_used['selector'] + ', ' + qty['selector'];
        $(all_elements).change(function() {
            price = calculate_prices();
            gform.addFilter("gform_product_total", function(total, formId) {
                if (formId == calculator_form_id) {
                    total = parseFloat(price);
                }
                return total;
            });
        });

        $(qty).on("input", function(e) {
            price = calculate_prices();
            gform.addFilter("gform_product_total", function(total, formId) {
                if (formId == calculator_form_id) {
                    total = parseFloat(price);
                }
                return total;
            });
        });

        function calculate_prices() {
            total_price = 0;
            if (laser_or_stempel.val() && used_or_new.val() && qty.val()) {
                if (metal_new.val() || metal_used.val()) {
                    laser_or_stempel_val = laser_or_stempel.val();
                    used_or_new_val = used_or_new.val();
                    metal_new_val = metal_new.val();
                    metal_used_val = metal_used.val();
                    qty_val = qty.val();

                    if (used_or_new_val == "Nieuw") {
                        if (metal_new_val == "Platina") {
                            prices = prices_calculator["platina"];
                        } else if (metal_new_val == "Goud") {
                            prices = prices_calculator["gold"];
                        } else if (metal_new_val == "Zilver") {
                            prices = prices_calculator["silver"];
                        } else if (metal_new_val == "Samengesteld: Goud - Zilver") {
                            prices = prices_calculator["gold_silver"];
                        } else if (metal_new_val == "Samengesteld: combinatie met Platina") {
                            prices = prices_calculator["combo_platina"];
                        }
                    } else {
                        if (metal_used_val == "Platina, Goud of Zilver") {
                            prices = prices_calculator["used"];
                        } else if (metal_new_val == "Samengestelde voorwerpen") {
                            prices = prices_calculator["used_combo"];
                        }
                    }

                    for (var i = 0, l = prices.length; i < l; i++) {
                        minum = prices[i]["minimaal"];
                        maxium = prices[i]["maximaal"];
                        price = prices[i]["prijs_per_item"];
                        if (maxium == "" && minum == "") {
                            used_price = price;
                        } else if (parseInt(qty_val) >= parseInt(minum) && parseInt(qty_val) <= parseInt(maxium)) {
                            used_price = price;
                        } else if (parseInt(qty_val) >= parseInt(minum) && maxium == "") {
                            used_price = price;
                        }
                    }
                    total_price = parseInt(qty_val) * parseFloat(used_price);
                    if (laser_or_stempel_val == "Lasergraveren") {
                        prices_lasser = prices_calculator["laser"];
                        for (var i = 0, l = prices_lasser.length; i < l; i++) {
                            minum = prices_lasser[i]["minimaal"];
                            maxium = prices_lasser[i]["maximaal"];
                            price = prices_lasser[i]["prijs_per_item"];
                            if (parseInt(qty_val) >= parseInt(minum) && parseInt(qty_val) <= parseInt(maxium)) {
                                used_price_laser = price;
                            } else if (parseInt(qty_val) >= parseInt(minum) && maxium == "") {
                                used_price_laser = price;
                            }
                        }
                        total_price_laser = parseInt(qty_val) * parseFloat(used_price_laser);
                        total_price = total_price + total_price_laser;
                    }
                }
            }
            return total_price;
        }
    } else {
        console.error( 'No rates have been specified' );
    }
});