// Common
import './menu';
import './site-search';
import './fancybox';
import './language-switch';

// Front-page
import './diensten-slider';

// Content & Gutenberg Blocks
import './faq';
import './price-calculator';

// Third party
import 'svgxuse';