jQuery(document).ready(function($) {
    var btnHamburger = $('.js-menu-hamburger');
    var btnClose = $('.js-menu-close');

    function updateNav() {
        $('.js-mobile-nav').toggle();
        $('body').toggleClass('mobile-nav-active');

        if ( $('body').hasClass('mobile-nav-active') ) {
            btnHamburger.hide();
            btnClose.show();
        } else {
            btnClose.hide();
            btnHamburger.show();
        }
    }

    btnHamburger.on('click', function(e) {
        e.preventDefault();
        updateNav();
    });

    btnClose.on('click', function(e) {
        e.preventDefault();
        updateNav();
    });
});