import '@accessible360/accessible-slick';

jQuery(document).ready(function($) {

    var dienstenSliderNav = $('.js-diensten-slider-nav');
    var dienstenSlider = $('.js-diensten-slider');
    var dienstenSliderOptions = {
        arrows: true,
        asNavFor: dienstenSliderNav,
        dots: false,
        infinite: true,
        lazyLoad: 'ondemand',
        slidesToScroll: 1,
        swipeToSlide: true,
        // adaptiveHeight: true,
        prevArrow: '<button type="button" class="slick-prev"><svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 7"><path d="M5.53 6.42L2.62 3.5 5.53.58 4.96.01 1.47 3.5l3.49 3.49z"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 7"><path d="M1.47.58L4.38 3.5 1.47 6.42l.57.57L5.53 3.5 2.04.01z"/></svg></button>'
    };

    dienstenSlider.slick(dienstenSliderOptions);

    dienstenSliderNav.find('.diensten-nav__item').on('click', function() {
        var index = $(this).closest('.slick-slide').data('slick-index');
        dienstenSlider.slick('slickGoTo', index);
    });

    var dienstenSliderNavOptions = {
        arrows: false,
        asNavFor: dienstenSlider,
        dots: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: false,
        draggable: false,
        focusOnSelect: false
    };

    dienstenSliderNav.slick(dienstenSliderNavOptions);

});